<template>
  <tr>
    <th class="facts-table__row-label">
      {{ label }}:
    </th>
    <td class="facts-table__row-icon">
      <country-flag
        v-if="flag"
        :country="flag"
        size="small"
      />
      <slot name="icon" />
    </td>
    <td class="facts-table__row-content">
      <template v-if="value">
        {{ value }}
      </template>
      <slot v-else />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'FactsRow',
  props: {
    label: {
      type: String,
      required: true
    },
    /**
     * If row value is plain text, use this prop as a shorthand to set content.
     */
    value: {
      type: [String, Number],
      default: null
    },
    /**
     * Country code for flag icon. If not set, no flag is shown.
     */
    flag: {
      type: String
    }
  },
}
</script>
