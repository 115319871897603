<template>
  <v-simple-table class="facts-table">
    <tbody>
      <slot />
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'FactsTable'
}
</script>

<style lang="scss">
.facts-table {
  &__row-label, &__row-icon {
    width: 1px;
    white-space: nowrap;
  }

  &__row-icon {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &__row-content {
    .v-image {
      max-width: 400px;
      display: inline-block;
      vertical-align: middle;
    }

    p:first-child {
      padding-top: 1rem;
    }
  }
}
</style>
